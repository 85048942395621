<template>
  <v-fade-transition hide-on-leave>
    <v-container v-if="firstName">
      <v-row justify="center">
        <v-card-title>
          🎉 {{ newUser ? $t('welcome_new_user') : $t('welcome_back') }}, {{  firstName }}! 🎉
        </v-card-title>
      </v-row>

      <v-row justify="center">
        <v-card-text class="text-body-1 text-center font-weight-bold">
          {{  $t('complete_experience') }}
        </v-card-text>
      </v-row>

      <v-row justify="center">
        <v-card-text class="text-body-1 text-center" v-html="$t('welcomeHook.content')">
        </v-card-text>
      </v-row>

      <v-row justify="center">
        <v-img max-width="200" src="@assets/images/Hierarchical Solver.jpeg"></v-img>
      </v-row>
    </v-container>
  </v-fade-transition>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { MediaURL } from '@components'
import { Signup, OAuth } from '@components/Auth'
import { isMobile, deviceDetect } from 'mobile-device-detect'
import API from '@api'

const { os, osName } = deviceDetect()
const deviceOs = osName ? osName : os

/* We test the deviceOs to make sure that the device detection will work for higher privacy browsers  */
const mobile = isMobile || deviceOs === 'Android' || deviceOs === 'iOS'
const deviceType = mobile ? 'mobile' : 'web'

export default {
  async created() {
    if (deviceType != 'mobile' || (!this.$auth.user && !this.user))
      this.$router.push('/')
    else {
      let email = this.$auth.user
        ? this.$auth.user.email
        : this.user ? this.user.email : undefined

      let fullName = this.$auth.user ? this.$auth.user.name : this.user.fullName
      this.firstName = fullName.split(' ')[0]

      const { exists } = await API().get('user-exists', {
        params: { email }
      })
      this.newUser = !exists // logic is incorrect for vanilla signup (but whatever)
      if(exists && !this.user) {
        const { user, profile } = await API().post('user/oauth-login', { email })
        await this.setSession({
          user,
          profile,
          auth0Token: await this.$auth.getTokenSilently()
        })

        const [anna] = await API().get(`profiles/${user.id}`, {
          params: {
            query: {
                'username': {
                  $eq: 'anna'
                }
              }
          }
        })
        this.SET_ANNA(anna)

        return;
      }

      this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
      this.submitAccount = Signup.submitAccount.bind(this);
      this.fetchImg = OAuth.methods.fetchImg.bind(this);
      this.fetchSrc = OAuth.methods.fetchSrc.bind(this);

      /*
      * Account creation
      */
      let avatar = await this.fetchImg({
        objectUrl: await this.fetchSrc(this.$auth.user.picture),
        name: this.$auth.user.name
      })

      if (!this.user) this.submitAccount({
        noSession: true,
        noRedirect: true,
        userId: this.$auth.user.sub,
        email: this.$auth.user.email,
        name: this.$auth.user.name,
        username: this.$auth.user.nickname.replace(/\./g, '_'),
        auth0Token: await this.$auth.getTokenSilently(),
        avatar // Sets the avatar on signup (irrelevant for signin)
      })
    }
  },

  data: () => ({
    firstName: '',
    newUser: true
  }),

  computed: {
    ...mapGetters({
      user: 'user'
    })    
  },

  methods: {
    ...mapMutations({
      SET_ANNA: 'anna'
    }),

    ...mapActions({
      setSession: 'setSession'
    }),
  }
}
</script>